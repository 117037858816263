import React, { useState, useEffect, useMemo, useRef }  from 'react';
import ActionButton from 'components/shared/Buttons/ActionButton';
import IconButton from 'components/shared/Buttons/IconButton';
import SecondaryButton from 'components/shared/Buttons/SecondaryButton';
import { createDocuments, getDocuments, deleteDocuments, updateDocuments } from 'services/hr';
import useApi from 'components/hooks/useApi';
import TablePlaceholder from 'components/shared/TablePlaceholder';
import { Form, Modal, Row, Col } from 'react-bootstrap';
import { useDispatch } from "react-redux";
import { setAlert } from 'redux/actions/alert';
import {getAttachment, uploadAttachment } from 'services/media';
import useLocalStorage from 'components/hooks/useLocalStorage';
import { getFileDetails } from 'utils/helper/string';
import ButtonLoader from 'components/shared/Buttons/ButtonLoader';
import TextInput from 'components/Forms/TextInput';
import ExtendTooltip from 'components/shared/ExtendTooltip';

const STUDENT = "STUDENT";
const STAFF = "STAFF";

function Documents(props: any) {

    const [s3_uniqId] = useLocalStorage("s3_uniqId", "");

    const dispatch = useDispatch();

    const {
        action: createDocumentsHelper,
      } = useApi(createDocuments, {error: true, success: true, noStore: true,
        onComplete: (data,error) =>{
            if(!error){
                handleClose();
                handleCloseCustom();
                handleFetchData();
            }
        }
      });

    const {
    action: deleteDocumentsHelper,
    } = useApi(deleteDocuments, {error: true, success: true, noStore: true});


    const {
        action: updateDocumentsHelper,
        } = useApi(updateDocuments, {error: true, success: true, noStore: true,
            onComplete: (data, error) =>{
                if(!error){
                    handleClose();
                    handleCloseCustom();
                    handleFetchData();
                }
            }
        });
    

    const {
    action: getDocumentsHelper,
    data: documentsData,
    loading: documentLoading
    } = useApi(getDocuments);


    const {
        action: uploadAttachmentHelper,
      } = useApi(uploadAttachment, {
        success: true,
        successMessage: "Upload successful!",
        error: true,
        errorMessage: "Failed to Upload file!"
      });

    

    const [profileData, setProfileData] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [documents, setDocuments] = useState(null)
    const [loadingStates, setLoadingStates] = useState([]);

    const [show, setShow] = useState(false);
    const [docDetails, setDocDetails] = useState<any>(null);

    const [filePreviews, setFilePreviews] = useState([]);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [profileType, setProfileType] = useState<"STAFF" | "STUDENT">(null);
    

    const requiredDocuments = props?.profileType === STUDENT
    ? [
        { name: "Aadhar Card", userType: STUDENT },
      ]
    : [
        { name: "Aadhar Card", userType: STAFF },
        { name: "Voter ID Card", userType: STAFF },
        { name: "Driving License", userType: STAFF },
      ];


    const [showCustom, setShowCustom] = useState(false);
    const [showPreviewModal, setShowPreviewModal] = useState(false);

    const [customPayload, setCustomPayload] = useState<any>({
        description: "",
    });
    const [errors, setErrors] = useState<any>({
        description: "",        
    });

    const [customSequenceNumber, setCustomSequenceNumber] = useState<number>(0);
    const [editId, setEditId] = useState(null);


    const firstUpdate = useRef(true);
    
    useEffect(() => {
        const loadDocuments = async()=>{
            if(documentLoading && documentsData) {
                setIsLoading(true);
                await setDocuments(processDocumentsData(documentsData?.data?.documents));
                firstUpdate.current = false;
                setIsLoading(false);
            } else if(props && firstUpdate.current) {
                setIsLoading(true)
                await setDocuments(processDocumentsData(props?.documentsData?.data?.documents));
                setIsLoading(false)
            }
        }
        loadDocuments()
        if (props?.profileData) {
            setProfileData(props.profileType === STUDENT ? props.profileData.userId : props.profileData);
        }        
        if(props?.profileType)
            setProfileType(props?.profileType)
    }, [documentLoading, documentsData, props]);

    const handleFetchData = () => {
        getDocumentsHelper({userType: profileType, owner: profileData?._id});
    }
    
    const processDocumentsData = (documents = []) => {
        const hardcodedDocumentNames = requiredDocuments.map((doc) =>
          doc.name.toLowerCase()
        );
      
        const processedDocuments = [
          ...requiredDocuments.map((template) => {
            const existingDoc = documents.find(
              (doc) => doc.name.toLowerCase() === template.name.toLowerCase()
            );
            return existingDoc
              ? existingDoc
              : { ...template, fileDetails: [],};
          }),
          ...documents.filter(
            (doc) => !hardcodedDocumentNames.includes(doc.name.toLowerCase())
          ),
        ];
      
        return processedDocuments;
      };
      
    const handleEdit = (item) => {
        setEditId(item?._id)
        setCustomPayload({description: item?.name})
        const isRequiredDocument = requiredDocuments.some(
            (doc) => doc.name === item.name
          );
        
          if (isRequiredDocument) {
            // Execute this block if the item name is in requiredDocuments
            handleSetPreviewFile(item);
            setShow(true);
            setDocDetails(item);
          } else {
            // Execute this block otherwise
            handleSetPreviewFile(item);
            setCustomSequenceNumber(0);
            setShowCustom(true);
            setDocDetails(item);
          }
    }


    const handleAdd = (item) => {
        const isRequiredDocument = requiredDocuments.some(
            (doc) => doc.name === item.name
          );
        
          if (isRequiredDocument) {
            // Execute this block if the item name is in requiredDocuments
            handleSetPreviewFile(item);
            setShow(true);
            setDocDetails(item);
          } else {
            // Execute this block otherwise
            handleSetPreviewFile(item);
            setCustomSequenceNumber(0);
            setShowCustom(true);
            setDocDetails(item);
          }
    }


    const handleClose = () => {
        setEditId(null);
        setFilePreviews([])
        setShow(false);
    }


    const handleAddCustom = () => {
        setShowCustom(true);
    }
    
    const handleCloseCustom = () => {
        setEditId(null);
        setCustomPayload({description: ""})
        setFilePreviews([])
        setCustomSequenceNumber(0);
        setShowCustom(false);
        setCurrentImageIndex(0);
    }

    const handleOpenPreview = (item) => {
        handleSetPreviewFile(item)
        setDocDetails(item)
        setShowPreviewModal(true);
    }
    
    const handleClosePreview = () => {
        setCurrentImageIndex(0);
        setEditId(null);
        setFilePreviews([])
        setShowPreviewModal(false);
    }


    const handleSetPreviewFile = (item?: any) => {
        const targetFileDetails = item ? item?.fileDetails : documents?.flatMap((doc) => doc?.fileDetails || []);
      
        if (targetFileDetails && targetFileDetails.length) {
          (async () => {
            const allPreviewAttachments: any[] = [];
      
            const previewAttachments = await Promise.all(
              targetFileDetails.map(async (fileItem, index) => {
                if (fileItem && !fileItem.filePath && fileItem?.file) {
                  // Generate preview for new files
                  return await handleSetUpPreviewFiles(
                    fileItem?.file as File,
                    item?.name || documents?.[0]?.name, // Use item name or fallback to document name
                    fileItem.sequenceNumber
                  );
                } else if (fileItem && (fileItem?.filePath || fileItem?.uploadedUrl)) {
                  // Handle already uploaded files
                  return await handlePresentFiles(fileItem);
                } else {
                  return null;
                }
              })
            );
      
            const processedAttachments = previewAttachments
              .filter((attachment) => attachment) // Remove null values
              .map((attachment, index) => ({
                ...attachment,
                sequenceNumber:
                  attachment.sequenceNumber || targetFileDetails[index].sequenceNumber,
                docName: item?.name || documents?.[0]?.name, // Add docName to preview
              }));
      
            allPreviewAttachments.push(...processedAttachments);

            setLoadingStates(Array(allPreviewAttachments.length).fill(true))

            setFilePreviews(allPreviewAttachments);
            setCustomSequenceNumber(processedAttachments?.length )
          })();
        }
      };
      
    const handleDeleteFile = (index: number) => {
    setFilePreviews(filePreviews.filter((_, i) => i !== index));
};

const handleDeleteFileCustom = (index: number) => {
        setFilePreviews(prev => {
            const updatedFiles = prev.filter((_, i) => i !== index);
    
            const reorderedFiles = updatedFiles.map((file, i) => ({
                ...file,
                sequenceNumber: i + 1,
            }));
            setCustomSequenceNumber(updatedFiles.length > 0 ? updatedFiles.length : 0);
            return reorderedFiles;
        });
    };

    const handlePresentFiles = async (file: any) => {
    try {
        if (file.uploadedUrl) {
        return {
            file: null,
            fileName: file.fileName,
            fileType: file.fileType,
            filePath: file.filePath,
            uploadedUrl: file.uploadedUrl,
            previewUrl: null,
            sequenceNumber: file.sequenceNumber,
        };
        }
        const { data } = await getAttachment(file.filePath);
        return {
        file: null,
        fileName: file.fileName,
        fileType: file.fileType,
        filePath: file.filePath,
        uploadedUrl: data,
        previewUrl: null,
        sequenceNumber: file.sequenceNumber,
        };
    } catch (error) {
        console.error("Error presenting file:", error);
    }
    };

    const handleSetUpPreviewFiles = (file: File, docName: string, sequenceNumber: number) => {
        return new Promise<{
            file: File;
            fileName: string;
            fileType: string;
            previewUrl: string;
            docName: string;
            sequenceNumber: number;
        }>((resolve, reject) => {
            const reader = new FileReader();
    
            reader.onload = () => {
                const blob = new Blob([reader.result as ArrayBuffer], {
                    type: file.type,
                });
                const previewUrl = URL.createObjectURL(blob);
    
                resolve({
                    file: file,
                    fileName: getFileDetails(file).fileName,
                    fileType: getFileDetails(file).fileType,
                    previewUrl: previewUrl,
                    docName: docName, 
                    sequenceNumber: sequenceNumber
                });
            };
    
            reader.onerror = () => {
                reject(reader.error);
            };
    
            reader.readAsArrayBuffer(file);
        });
    };

    const handleFileChange = (files: File | FileList, docName: string, sequenceNumber: number) => {
        if (files) {
            const fileArray = files instanceof FileList ? Array.from(files) : [files];
        
        const fileDataPromises = fileArray.map((file) =>
            handleSetUpPreviewFiles(file, docName, sequenceNumber)
        );
            Promise.all(fileDataPromises)
            .then((fileData) => {
                setFilePreviews((prev) => [
                    ...prev.filter((file) => file.sequenceNumber !== sequenceNumber),
                    ...fileData
                ]);
            })
            .catch((error) => {
                console.error("Error reading file:", error);
            });
        }
    };
    
    
    const inputRefMultiple = useRef<HTMLInputElement>(null);

    const inputRefFront = useRef<HTMLInputElement>(null);
    const inputRefBack = useRef<HTMLInputElement>(null);


    const isFileInvalid = (
        selectedFiles: File | FileList, 
        sequenceNumber: number,
    ): boolean => {

        if (filePreviews.some(file => (file.sequenceNumber === sequenceNumber))) {
            dispatch(setAlert({
                msg: "Please remove the existing file before uploading a new one.",
                status: 400,
                alertType: "danger",
            }));
            return true;
        }

        const fileArray = selectedFiles instanceof FileList ? Array.from(selectedFiles) : [selectedFiles];

        let fileName = "";
        if (Array.from(fileArray).some(file => {
            fileName = file.name.split('.').slice(0, -1).join('.');
            return filePreviews.some(preview => preview.fileName === fileName);
        })) {
            dispatch(setAlert({
                msg: `${fileName} file has already been uploaded or has the same name as a previous file.`,
                status: 400,
                alertType: "danger",
            }));
            return true;
        }

    
        return false;
    };
    

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
      };
    
    
    const handleDrop = (e: React.DragEvent<HTMLDivElement>, docName?: string, sequenceNumber?: number) => {
        e.preventDefault();
        e.stopPropagation();
    
        const selectedFiles = e.dataTransfer.files;

        if (!selectedFiles) return;

        
        if(docName === "others" && !customPayload?.description){
            dispatch(setAlert({
                msg: "Please enter name before upload",
                status: 400,
                alertType: "danger",
            }));
            return;
        }
        
        const actualDocName = (docName === "others" ? customPayload?.description : docName);
        
        if (docName === "others") {
            Array.from(selectedFiles).forEach((file, index) => {
              const newSequenceNumber = (customSequenceNumber) + index + 1;
        
              if (isFileInvalid(file, newSequenceNumber)) {
                return;
              }
              
              setCustomSequenceNumber(newSequenceNumber);
              handleFileChange(file, actualDocName, newSequenceNumber);
            });
          } else {
            if (isFileInvalid(selectedFiles, sequenceNumber)) {
              return;
            }
        
            handleFileChange(selectedFiles, docName, sequenceNumber);
          }
        
        };
    
      
    const handleSelectImages = (
        e: React.ChangeEvent<HTMLInputElement>, 
        docName?: string, 
        sequenceNumber?: number
    ) => {
        const selectedFiles = e.target.files;
    
        if (!selectedFiles) return;

        
        if(docName === "others" && !customPayload?.description){
            dispatch(setAlert({
                msg: "Please enter name before upload",
                status: 400,
                alertType: "danger",
            }));
            return;
        }

        const actualDocName = (docName === "others" ? customPayload?.description : docName);
                
          if (docName === "others") {
            Array.from(selectedFiles).forEach((file, index) => {
              const newSequenceNumber = (customSequenceNumber) + index + 1;
        
              if (isFileInvalid(file, newSequenceNumber)) {
                return;
              }
              setCustomSequenceNumber(newSequenceNumber);
              handleFileChange(file, actualDocName, newSequenceNumber);
            });
          } else {
            if (isFileInvalid(selectedFiles, sequenceNumber)) {
              return;
            }
            handleFileChange(selectedFiles, docName, sequenceNumber);
          }
        
        };
    
    
    

    const handleSave = async() =>{
        let check = false

        const newErrors = {...errors}
        if(filePreviews?.[0]?.docName === "others" && !customPayload?.description){
            newErrors.description = "Please enter name"
            check = true
        }
        setErrors(newErrors)

        if(check){
            dispatch(setAlert({
                msg: "Please enter name",
                status: 400,
                alertType: "danger"
            }))
            return;
            }

        const formattedFiles = filePreviews.map(
            ({ file, fileName, fileType, filePath, uploadedUrl, docName, sequenceNumber }) => {
              if (filePath) {
                return {
                    formData: null,
                    filePath,
                    fileName,
                    fileType,
                    uploadedUrl,
                    docName,
                    sequenceNumber,
                };
              }
              const formData = new FormData();
              formData.append("file", file);
              formData.append("uniqueId", s3_uniqId);
      
              if (fileType === "jpg" || fileType === "png" || fileType === "jpeg") {
                formData.append("type", "image");
              } else {
                formData.append("type", "document");
              }
              return {
                    formData,
                    fileName,
                    fileType,
                    file,
                    docName, 
                    sequenceNumber
              };
            }
          );

          let finalData: any = {};

        finalData.name = customPayload?.description || formattedFiles?.[0]?.docName,
        finalData.owner = profileData?._id,
        finalData.userType = profileType,
        
          finalData.fileDetails = await Promise.all(
            (formattedFiles || []).map(async (attachment) => {
                if (attachment?.formData && !attachment.filePath) {
                const data = await uploadAttachmentHelper(attachment.formData);
                if (data) {
                    const attachmentPayload = {
                    filePath: data.key,
                    fileName: attachment.fileName,
                    fileType: attachment.fileType,
                    sequenceNumber: attachment.sequenceNumber
                    };
                    return attachmentPayload;
                } else {
                    return null;
                }
                } else {
                return {
                    filePath: attachment.filePath,
                    fileName: attachment.fileName,
                    fileType: attachment.fileType,
                    sequenceNumber: attachment.sequenceNumber
                };
                }
            })
          );

          if(!(finalData.fileDetails && finalData.fileDetails?.length)){
            dispatch(
                setAlert({
                    msg: "upload atleast one file!",
                    alertType: "danger",
                    status: 500
                })
            );
            return;
          }

          if(editId){
            updateDocumentsHelper(finalData, editId)
          } else {
              createDocumentsHelper(finalData)
          }
    };


    const handleNextImage = () => {
        if (currentImageIndex < filePreviews.length - 1) {
        setCurrentImageIndex(currentImageIndex + 1);
        }
    };
    
    const handlePreviousImage = () => {
        if (currentImageIndex > 0) {
        setCurrentImageIndex(currentImageIndex - 1);
        }
    };

    const findPreviewAndIndex = (filePreviews, docName, sequenceNumber) => {
        let result = { index: -1, preview: null };
    
        filePreviews.some((file, idx) => {
            if (file.docName === docName && file.sequenceNumber === sequenceNumber) {
                result = { index: idx, preview: file };
                return true; // Exit early when the match is found
            }
            return false; // Continue iterating
        });
    
        return result;
    };

    const handleDocumentName = (value) => {
        if(documents.some((item) => item?.name === value)){
            dispatch(setAlert({
                msg: "The entered name already exists",
                status: 400,
                alertType: "danger",
            }));
            return;
        }
        setCustomPayload({...customPayload, description: value})

    };


    const handleDownloadAttachments = async (file) => {
        const data: any = await getAttachment(file?.filePath);
        if (data.status < 400) {
            const a = document.createElement("a");
            a.href = data?.data;
            // a.target = "_blank";
            a.click();
        } else {
            dispatch(
            setAlert({
                msg: "Failed to download the attachment!",
                alertType: "danger",
                status: 500
            })
            );
            return;
        }
    };

    return (
        <div>
            <div className="profile-headers mt-3 ms-16 me-4 ">
                <IconButton
                    action="add-regular"
                    classes="ms-auto theme-table-scroll-head mb-3"
                    styles={{
                        position: "sticky",
                        top: "0",
                    }}
                    onClickHandler={handleAddCustom}>
                    Add Documents
                </IconButton>
            </div>
            <div className='theme-table-scroll-container theme-content-table theme-table-scroll-head' style={{height :"400px"}}>
            {isLoading ? ( <TablePlaceholder noOfCopies={10} />) : 
                (documents && (documents || [])?.map((item, idx)=> (
                    <div key={idx} className="d-flex align-items-center gap-5 docs-main-background justify-content-around mt-3 mx-3 px-4 py-3 border-radius-8">                            
                            <p className="fs-header fw-bold me-auto mb-0"><span><img src="/images/documentsIcon.svg" alt="document icon" /></span> <span className="ms-3">{item?.name}</span></p>
                            
                            <p onClick={()=> item?.fileDetails?.length ? handleOpenPreview(item) : null} className={`${item?.fileDetails?.length ? "theme-primary-color cursor-pointer" : "upload-doc-text"} fs-text fw-bold mb-0`}><span><img src="/images/files-icon.svg" width={16} height={16} alt="" /></span> <span className="ms-1">{item?.fileDetails?.length} Files</span></p>
                       { item?.fileDetails?.length  ? 
                       <SecondaryButton
                            view="regular"
                            onClickHandler={()=>handleEdit(item)}
                            classes="d-flex align-items-center gap-2"
                        >
                            <img src="/images/table-edit-btn.svg" width={16} height={16} alt="Edit" />
                            <span>{`${"Edit"}`}</span>
                        </SecondaryButton>
                        :
                        <SecondaryButton
                            view="regular"
                            onClickHandler={()=>handleAdd(item)}
                            classes="d-flex align-items-center gap-2"
                        >
                            <img src="/images/add-btn-blue.svg" width={12} height={12} alt="add" />
                            <span>{`${"Add"}`}</span>
                        </SecondaryButton>
                        }

                    </div>
                    )))}
                </div>

    <Modal
        className="upload-doc-modal-md"
        show={show}
        centered
        onHide={handleClose}
        backdrop={show ? "static" : true}
      >
        <Modal.Header closeButton={show}>Documents</Modal.Header>
        <Modal.Body>
            <Row className="mx-0 w-100 mw-100 justify-content-center">
                {documents && (documents || []).filter((doc) => doc.name === docDetails?.name).map((doc) => {
                    return (
                    <div key={doc.name} className="d-flex mx-0 ps-0 pe-3 flex-row w-100 gap-3">
                        {["frontPage", "backPage"].map((page) => {
                        const sequenceNumber = page === "frontPage" ? 1 : 2;
                        const { index, preview } = findPreviewAndIndex(filePreviews, doc.name, sequenceNumber);

                        const isFrontPage = page === "frontPage";

                        const inputRef = page === "frontPage" ? inputRefFront : inputRefBack;

                        return (
                            <div className="col-md-6 flex-grow-1" key={sequenceNumber} >
                            <h5 className="fs-text fw-bold mb-2 mt-3">
                                Upload File ({isFrontPage ? "Front Page" : "Back Page"})
                            </h5>

                            <div className="upload-doc-img-container d-flex flex-column align-items-center justify-content-center cursor-pointer"
                            onDragOver={handleDragOver}
                                onDrop={(e) => handleDrop(e, doc.name, sequenceNumber)}>
                            {preview ? (
                                <div className="image-preview-container position-relative">
                                {(preview.fileType === "jpg" ||
                                preview.fileType === "png" ||
                                preview.fileType === "jpeg") ?
                                <div>
                                    {/* {loadingStates[index] && (
                                            <>
                                            <ButtonLoader />
                                            </>
                                        )} */}
                                <img
                                    src={preview.uploadedUrl ? preview.uploadedUrl : preview.previewUrl}
                                    // onLoad={() => {
                                    //     const updatedLoadingStates = [...loadingStates];
                                    //     updatedLoadingStates[index] = false;
                                    //     setLoadingStates(updatedLoadingStates);
                                    //   }}
                                    alt={`${doc.name} ${page}`}
                                    className="img-fluid image-preview"
                                />
                                <img src="/images/red-close-icon.svg"
                                    className="doc-image-delete-button"
                                    style={{ width: '20px', height: '20px',
                                        // display: loadingStates[index] ? 'none' : 'block',
                                    }}
                                    alt="Close Attachment"
                                    onClick={() => handleDeleteFile(filePreviews.findIndex(file => file === preview))} 
                                    />
                                    </div>
                                 :
                                    <div>
                                    <a
                                    href={preview.uploadedUrl ? preview.uploadedUrl : preview.previewUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    >
                                    View {preview.fileName}
                                    </a>
                                    <img src="/images/red-close-icon.svg"
                                        className="doc-document-delete-button"
                                        style={{ width: '20px', height: '20px',
                                        }}
                                        alt="Close Attachment"
                                        onClick={() => handleDeleteFile(filePreviews.findIndex(file => file === preview))} 
                                        />
                                    </div>}
                                </div>
                            ) : (
                                <div>
                                <div className="d-flex align-items-center justify-content-center gap-3 mt-3">
                                    <img src="/images/folder-add.svg" width={20} height={24} alt="Upload" />
                                    <h5 className="mb-0 fs-header fw-bold">Drop Here</h5>
                                </div>
                                <p className="upload-doc-text fs-small mt-2 ms-2">
                                    Drag and drop or{" "}
                                    <span
                                    className="browse"
                                    onClick={() => inputRef.current?.click()}
                                    style={{ color: "blue", cursor: "pointer" }}
                                    >
                                    browse
                                    </span>{" "}
                                    your files
                                </p>
                                <Form.Control
                                    type="file"
                                    // accept="image/*"
                                    className="theme-file-select d-none"
                                    ref={inputRef}
                                    onChange={(e) =>
                                    handleSelectImages(e as React.ChangeEvent<HTMLInputElement>, doc.name, sequenceNumber)
                                    }
                                />
                                </div>
                            )}
                            </div>
                            </div>
                        );
                        })}
                    </div>
                    );
                })}

                <div className="d-flex justify-content-end align-items-center pe-0 mt-3">
                    <ActionButton view="regular" onClickHandler={() => handleSave()}>
                    Save
                    </ActionButton>
                    <button type="button" className="theme-btn theme-cancel-btn ms-16" onClick={handleClose}>
                    Cancel
                    </button>
                </div>
                </Row>

            </Modal.Body>
        </Modal>


{/* custom model below */}
        <Modal
            className="custom-upload-doc-modal-lg"
            show={showCustom}
            centered
            onHide={handleCloseCustom}
            backdrop={showCustom ? "static" : true}
        >
            <Modal.Header closeButton={showCustom}>Documents</Modal.Header>
            <Modal.Body>
                <Row className="mx-0 d-flex w-100 mw-100 gap-3 ">
                    <div className="col-md-6 mx-0 ps-0 d-flex flex-column">
                        <Col md={6} className="mb-3">
                            <TextInput
                                label=" Name of the Document"
                                required={true}
                                className="theme-input-control"
                                type="text"
                                placeholder="Enter the Name"
                                value={customPayload.description || ""}
                                onChange={(e)=> handleDocumentName(e.target.value)}
                                showError={!!errors.description}
                                validationMessage={errors.description?.message}
                                />
                        </Col>
                            <h5 className="fs-text fw-bold mb-2">Upload File</h5>
                            <div className='custom-upload-doc-img-container d-flex flex-column align-items-center justify-content-center cursor-pointer'
                                onDragOver={handleDragOver}
                                onDrop={(e) => handleDrop(e, "others")}                            
                            >
                                    
                                    <div className="d-flex align-items-center d-flex justify-content-center align-item-center gap-3 mt-3">
                                    <img src="/images/folder-add.svg" width={20} height={24} alt="Upload" />
                                    <h5 className="mb-0 fs-heading fw-bold">Drop Here</h5>
                                </div>
                                <p className="upload-doc-text fs-small mt-2 ms-2">
                                    Drag and drop or{" "}
                                    <span
                                    className="browse"
                                    onClick={() => inputRefMultiple.current?.click()}
                                    style={{ color: "blue", cursor: "pointer" }}
                                    >
                                    browse
                                    </span>{" "}
                                    your files
                                </p>
                                <Form.Control
                                    type="file"
                                    multiple                                    
                                    className="theme-file-select d-none"
                                    ref={inputRefMultiple}
                                    onChange={(e) =>
                                    handleSelectImages(e as React.ChangeEvent<HTMLInputElement>, "others")
                                    }
                                />
                            </div>

                            <h5 className="fs-text fw-bold mb-2 mt-4">All Images</h5>
                            <div className="mb-5 custom-all-image-info scroll-bar-visible">
                                {filePreviews && filePreviews.length > 0 ? (
                                    filePreviews.map((preview, index) => (
                                    <div key={index} >
                                        <p className="d-flex align-items-center mb-3">
                                            
                                            <img src="/images/image-or-photo-icon.svg"
                                                className="custom-doc-image-logo-icon"
                                                style={{ width: '20px', height: '20px',
                                                }}
                                                alt="photo icon"
                                            />
                                            <span className="ps-4 ms-2">{`${preview.fileName}.${preview.fileType}`}</span> 
                                            <img src="/images/red-close-icon.svg"
                                                className="custom-doc-image-delete-button"
                                                style={{ width: '20px', height: '20px',
                                                }}
                                                alt="Close Attachment"
                                                onClick={() => handleDeleteFileCustom(index)} 
                                                />
                                        </p>
                                    </div>
                                    ))
                                ) : (
                                    <p>No images uploaded.</p>
                                )}
                            </div>

                    </div>
                    <div className="custom-image-container docs-main-background d-flex flex-column align-items-center justify-content-center col-md-5 flex-grow-1">
                        {/* Display current image preview */}
                        {filePreviews.length > 0 ? (
                            <>
                        <div className="col-md-12 d-flex align-items-center justify-content-between py-3">
                            <button
                                className="theme-btn"
                                onClick={handlePreviousImage}
                                disabled={currentImageIndex === 0}
                                >
                                <img
                                    src="/images/lesser-than-icon.svg"
                                    alt="right navigation icon"
                                    className="img-fluid"
                                    style={{width: '10px', height: "15px"}}
                                />
                                </button>
                                <div className="fw-bold fs-text">
                                {filePreviews[currentImageIndex]?.fileName?.length > 25 ? 
                                    <span><ExtendTooltip info={filePreviews[currentImageIndex]?.fileName} name={filePreviews[currentImageIndex]?.fileName > 25 ? `${filePreviews[currentImageIndex]?.fileName.substring(0, 25)}...` : `${filePreviews[currentImageIndex]?.fileName}...`} /></span> 
                                    : `${filePreviews[currentImageIndex]?.fileName}.${filePreviews[currentImageIndex]?.fileType}`}
                                    </div>                                
                            <button
                                className="theme-btn"
                                onClick={handleNextImage}
                                disabled={currentImageIndex === filePreviews.length - 1}
                                >
                                <img
                                    src="/images/greater-than-icon.svg"
                                    alt="right navigation icon"
                                    className="img-fluid"
                                    style={{width: '10px', height: "15px"}}
                                />
                                </button>
                            </div>
                            <div className="custom-image-preview-container position-relative">
                            {/* {loadingStates[currentImageIndex] && (
                                            <div className="d-flex ms-auto">
                                            <ButtonLoader />
                                            </div>
                                        )} */}
                            {(filePreviews[currentImageIndex]?.fileType === "jpg" ||
                                filePreviews[currentImageIndex]?.fileType === "png" ||
                                filePreviews[currentImageIndex]?.fileType === "jpeg") ?
                                <>
                                <img
                                    src={filePreviews[currentImageIndex]?.previewUrl  ? filePreviews[currentImageIndex]?.previewUrl : filePreviews[currentImageIndex]?.uploadedUrl}
                                    alt={filePreviews[currentImageIndex]?.fileName}
                                    className="img-fluid custom-image-preview"
                                    // onLoad={() => {
                                    //     const updatedLoadingStates = [...loadingStates];
                                    //     updatedLoadingStates[currentImageIndex] = false;
                                    //     setLoadingStates(updatedLoadingStates);
                                    // }}
                                />
                            </>

                            :
                            <a
                                href={filePreviews[currentImageIndex]?.uploadedUrl ? filePreviews[currentImageIndex]?.uploadedUrl : filePreviews[currentImageIndex]?.previewUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                                >
                                View {filePreviews[currentImageIndex]?.fileName}
                                </a>}
                            </div>
                            </>
                        ) : (
                            <p>No images uploaded.</p>
                        )}
                    </div>
                    <div className="d-flex justify-content-end align-items-center pe-0 mt-2">
                        <ActionButton view="regular" onClickHandler={() => handleSave()}>
                        Save
                        </ActionButton>
                        <button type="button" className="theme-btn theme-cancel-btn ms-16" onClick={handleCloseCustom}>
                        Cancel
                        </button>
                    </div>
                </Row>
            </Modal.Body>
        </Modal>

{/* preview modal below */}
        <Modal
            className="preview-upload-doc-modal-lg"
            show={showPreviewModal}
            centered
            onHide={handleClosePreview}
            backdrop={showPreviewModal ? "static" : true}
        >
            <Modal.Header closeButton={showPreviewModal}>Documents</Modal.Header>
            <Modal.Body>
                <Row className="mx-0 d-flex px-5 w-100 mw-100">
                    <div className="preview-image-container docs-main-background d-flex flex-column align-items-center justify-content-center">
                        {/* Display filtered image previews */}
                        {filePreviews.length > 0 ? (
                        <>
                            {/* Get the current file based on currentImageIndex */}
                            {filePreviews[currentImageIndex]?.docName === docDetails?.name && (
                            <React.Fragment>
                                {/* Display current file name */}
                                <div className="col-md-12 d-flex align-items-center justify-content-between py-3">
                                <div className="fw-bold fs-text">
                                    {filePreviews[currentImageIndex]?.fileName?.length > 50 ? 
                                    <span><ExtendTooltip info={filePreviews[currentImageIndex]?.fileName} name={filePreviews[currentImageIndex]?.fileName > 50 ? `${filePreviews[currentImageIndex]?.fileName.substring(0, 50)}...` : `${filePreviews[currentImageIndex]?.fileName}...`} /></span> 
                                    : `${filePreviews[currentImageIndex]?.fileName}.${filePreviews[currentImageIndex]?.fileType}`}
                                </div>
                                </div>
                                {/* Align buttons and image side by side */}
                                <div className="d-flex align-items-center justify-content-center gap-2">
                                {/* Lesser Than Button */}
                                <button
                                    className="theme-btn"
                                    onClick={handlePreviousImage}
                                    disabled={currentImageIndex === 0}
                                >
                                    <img
                                    src="/images/lesser-than-icon.svg"
                                    alt="left navigation icon"
                                    className="img-fluid"
                                    style={{ width: '10px', height: '15px' }}
                                    />
                                </button>

                                {/* {loadingStates[currentImageIndex] && (
                                            <>
                                            <ButtonLoader />
                                            </>
                                        )} */}

                                {/* Document Image */}
                                <div className="preview-image-preview-container position-relative">
                                    {['jpg', 'png', 'jpeg'].includes(
                                    filePreviews[currentImageIndex]?.fileType
                                    ) ? (
                                    <img
                                        src={filePreviews[currentImageIndex]?.uploadedUrl}
                                        alt={filePreviews[currentImageIndex]?.fileName}
                                        className="img-fluid preview-image-preview"
                                        // onLoad={() => {
                                        //     const updatedLoadingStates = [...loadingStates];
                                        //     updatedLoadingStates[currentImageIndex] = false;
                                        //     setLoadingStates(updatedLoadingStates);
                                        //   }}
                                    />
                                    ) : (
                                    <a
                                        href={
                                        filePreviews[currentImageIndex]?.uploadedUrl ||
                                        filePreviews[currentImageIndex]?.previewUrl
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        View {filePreviews[currentImageIndex]?.fileName}
                                    </a>
                                    )}
                                </div>

                                {/* Greater Than Button */}
                                <button
                                    className="theme-btn"
                                    onClick={handleNextImage}
                                    disabled={currentImageIndex === filePreviews.length - 1}
                                >
                                    <img
                                    src="/images/greater-than-icon.svg"
                                    alt="right navigation icon"
                                    className="img-fluid"
                                    style={{ width: '10px', height: '15px' }}
                                    />
                                </button>
                                </div>
                            </React.Fragment>
                            )}
                        </>
                        ) : (
                        <p>No images uploaded.</p>
                        )}
                    </div>
                    <div className="d-flex justify-content-end align-items-center mt-3">
                    <button
                        type="button"
                        className="theme-btn theme-action-btn ms-16"
                        onClick={()=>handleDownloadAttachments(filePreviews[currentImageIndex])}
                        >
                        Download
                        </button>
                        <button
                        type="button"
                        className="theme-btn theme-cancel-btn ms-16"
                        onClick={handleClosePreview}
                        >
                        Cancel
                        </button>
                    </div>
                    </Row>



            </Modal.Body>
        </Modal>
        
        </div>
    )
}
export default Documents;
