import DOMPurify from "dompurify";
import { TextFormatTypes } from "./types";



const formatUnderscore = (str: string) => 
  str.toLowerCase().replace(/_/g, " ").replace(/\b\w/g, (match) => match.toUpperCase());

const formatFirstLetter = (str: string) => {
  str = str.toLowerCase().replace(/_/g, " ");
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const formatTitleCase = (str: string) => 
  str.toLowerCase().replace(/_/g, " ").replace(/\b\w/g, (c) => c.toUpperCase());

export const textFormatting = (item: string, format?: TextFormatTypes): string => {
  if (!item || !item.length) return "-";

  switch (format) {
    case "UNDERSCORE":
      return formatUnderscore(item);
    case "FIRST_LETTER":
      return formatFirstLetter(item);
    case "TITLE_CASE":
      return formatTitleCase(item);
    default:
      return formatUnderscore(item);
  }
};





// nameFormat - get the full Name and length
export const nameFormat = (person: {
    firstName?: string;
    middleName?: string;
    lastName?: string;
    username?: string;
  }) : {fullName : string, length: number} => {
    const textFormatting = (item: string) => {
      if (item && item.length) {
        return item.toLowerCase().replace(/\b\w/g, (match) => match.toUpperCase());
      }
      return "";
    };
  
    const displayName = () => {
      if (!person?.firstName) {
        return person?.username || "";
      }
      return `${person?.firstName} ${person?.middleName || ""} ${person?.lastName || ""}`.trim();
    };
  
    const formattedName = textFormatting(displayName()) || '-';
    const length = formattedName.length;
  
    return { fullName: formattedName, length };
  };
  

export const isMongoId = (id) => {
    // Regular expression to match MongoDB ObjectId (hexadecimal string of length 24)
    const objectIdPattern = /^[0-9a-fA-F]{24}$/;

    return objectIdPattern.test(id);
}

export function convertToTitleCase(str : string) {
    return str.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
}

export function convertHtmlToTextWithoutTags(html) {
  const tempDiv = document.createElement("div");
  const cleanHTML = purifyHTMLContent(html)
  tempDiv.innerHTML = cleanHTML;
  return tempDiv.textContent || tempDiv.innerText || "";
}

// purifyHTMLContent - Clean up HTML input from user using some malicious tags
export const purifyHTMLContent = (htmlContent: string) => {
  const cleanHTML = DOMPurify.sanitize(htmlContent);
  return cleanHTML;
}

export const getFileDetails = (file : File) =>{
  if (!file || !file.name) {
      return {
          fileName: "",
          fileType: "",
      };
  }

  const fileName = file.name.split('.').slice(0, -1).join('.') || file.name;
  const fileType = file.name.split('.').pop() || "";

  return { fileName, fileType };
}

