import React from 'react'

type ActionButtonProps = {
    view: "modal" | "regular" | "table",
    onClickHandler?: () => any,
    classes?: string,
    styles?: Object,
    formId?: string,
    disabled?: boolean,
}

const ActionButton: React.FC<ActionButtonProps> = ({onClickHandler, view, classes, styles, formId, disabled, children}) => {
  if(view === "modal")
    return (
        <button form={formId ? formId: ''} disabled={disabled ? disabled : false} onClick={onClickHandler} 
        className={`theme-modal-btn theme-action-btn ${classes ? classes : ""}`} style={styles ? styles : {}}>
        {children}
        </button>
    ) 
  else if(view === "regular")
    return (
        <button form={formId ? formId: ''} disabled={disabled ? disabled : false} onClick={onClickHandler} 
        className={`theme-btn theme-action-btn ${classes ? classes : ""}`} style={styles ? styles : {}}>
        {children}
        </button>
    )
  else 
    return (
        <button form={formId ? formId: ''} disabled={disabled ? disabled : false} onClick={onClickHandler} 
        className={`theme-tabular-action-btn ${classes ? classes : ""}`} style={styles ? styles : {}}>
        {children}
        </button>
    )
}

export default ActionButton;
