import axios from "utils/axios";
import axiosnoti from "utils/axiosnoti";

export async function getJobReports(data, log) {
    try {
        
        switch(log.scheduleJobType){
            case "FEE_DAY_SHEET" : {
                return await axios.post(`jobs/sendFeeDaySheetData`, data, 
                {
                    responseType: 'blob' 
                });
            }
            case "CLASS_WISE_PAID_DUE_REPORT" : {
                return await axios.post(`jobs/sendClassWiseFeeReport`, data, 
                {
                    responseType: 'blob' 
                });
            }
            case "STAFF_ATTENDANCE_SUMMARY" : {
                return await axios.post(`jobs/sendStaffSummary`, data, 
                {
                    responseType: 'blob' 
                });
            }
            case "STUDENT_ATTENDANCE_SUMMARY" : {
                return await axios.post(`jobs/sendStudentSummary`, data, 
                {
                    responseType: 'blob' 
                });
        
            }
            case "SMS_SENT_REPORT" : {
                return await axiosnoti.post(`notificationDump/smsreport`,  {...data, type : data.scheduleJobType}, 
                {
                    responseType: 'blob' 
                });
            }
            default : {
                
                return ''
            }
          }

        
    } catch (error) {
        return error;
    }
}


export async function getBranchesByOrgId(data) {
    try {
        let moduleId = localStorage.getItem("routeId");
        return await axios.post(`branch/getbranches`, {...data, moduleId});

        
    } catch (error) {
       
        return error
    }
}

export async function getRolesByOrgId(body) {
    try {
        return await axios.post(`role/getRolesByOrgId`,body);

        
    } catch (error) {
       
        return error
    }
}

export async function getRolesBybranchId(id) {
    try {
        return await axios.get(`role/getRolesByBranchId/${id}`);

        
    } catch (error) {
       
        return error
    }
}

// API : v1/role/getRoles
export async function getallRoles(data) {
    try {
        return await axios.post(`role/getRoles`,data);

        
    } catch (error) {
       
        return error
    }
}

// API : v1/role/getRolesByOrgId
export async function getRolesById(data) {
    try {
        return await axios.post(`role/getRolesByOrgId`,data);

        
    } catch (error) {
       
        return error
    }
}

// API : v1/academicclass/getClassSections
export async function getCombinedClassAndSections(data) {
    try {
        return await axios.post(`academicclass/getClassSections`, data);

    } catch (error) {
        return error
    }
}

// API : v1/academicclass/getClassSectionsByUserId
export async function getCombinedClassAndSectionsByUser(data) {
    try {
        return await axios.post(`academicclass/getClassSectionsByUserId`, data);

    } catch (error) {
        return error
    }
}

// API : v1/academicsection/getSectionsWithCounts
export async function getCombinedSectionsWithCount(data) {
    try {
        return await axios.post(`academicsection/getSectionsWithCounts`, data);

    } catch (error) {
        return error
    }
}

// API : v1/branchacademicbatch/getBranchAcademicBatches
export async function getBranchAcademicBatches(data) {
    try {
        return await axios.post(`branchacademicbatch/getBranchAcademicBatches`, data);

    } catch (error) {
        return error
    }
}


// academicbatch/getAcademicBatches

export async function getAcademicBatches(data) {
    try {
        return await axios.post(`academicbatch/getAcademicBatches`, data);

    }
    catch(error){
        return error;
    }
}


// API : v1/academicbatch/initialsetup/create
export async function createInitialSetupAcademicBatch(data) {
    try {
        return await axios.post(`academicbatch/initialsetup/create`, data);

    } catch (error) {
        return error
    }
}

// API : v1/classcategory/initialsetup/create
export async function createInitialSetupClassCategory(data) {
    try {
        return await axios.post(`classcategory/initialsetup/create`, data);

    } catch (error) {
        return error
    }
}


// API : v1/mastersubject/initialsetup/create
export async function createInitialSetupSubjects(data) {
    try {
        return await axios.post(`mastersubject/initialsetup/create`, data);

    } catch (error) {
        return error
    }
}


// API : v1/role/createRoles
export async function createInitialSetupRoleManagement(data) {
    try {
        return await axios.post(`role/createRoles`, data);

    } catch (error) {
        return error
    }
}


// API : v1/user/usersRegister
export async function createInitialSetupUserManagement(data) {
    try {
        return await axios.post(`user/usersRegister`, data);

    } catch (error) {
        return error
    }
}


// API : v1/classcategory/getClassCategories
export async function getClassCategory(data) {
    try {
        return await axios.post(`classcategory/getClassCategories`, data);

    } catch (error) {
        return error
    }
}

// API : v1/classcategory/getClassCategories
export async function getBoardnames() {
    try {
        return await axios.get(`board/getBoards`);

    } catch (error) {
        return error
    }
}

// API : v1/mastersubject/getMasterSubjects
export async function getMastersubjects(data) {
    try {
        return await axios.post(`mastersubject/getMasterSubjects`, data)

    } catch (error) {
        return error
    }
}


// UNDO APPROVAL SETTINGS APIS


// API : v1/undosettings/getSettings
export async function getUndoApprovalSettings(data) {
    try {
        return await axios.post(`undosettings/getSettings`, data)

    } catch (error) {
        return error
    }
}

// API : v1/undosettings/create
export async function createUndoApprovalSettings(data) {
    try {
        return await axios.post(`undosettings/create`, data)

    } catch (error) {
        return error
    }
}

// API : v1/undosettings/update/id
export async function updateUndoApprovalSettings(id , body) {
    try {
        return await axios.put(`undosettings/update/${id}`, body)

    } catch (error) {
        return error
    }
}

// ORG LEVEL USERS START
// API : v1/user/getOrgLevelUsers
export async function getOrgLevelUsers(body) {
    try {
        return await axios.post(`user/getOrgLevelUsers`, body)

    } catch (error) {
        return error?.response;
    }
}
// API : v1/user/addOrgUser
export async function addOrgLevelUser(body) {
    try {
        return await axios.post(`user/addOrgUser`, body)

    } catch (error) {
        return error;
    }
}
// API : v1/user/update/:id
export async function editOrgLevelUser(body, id) {
    try {
        return await axios.patch(`user/users/${id}`, body)

    } catch (error) {
        return error;
    }
}

// API : v1/orguserpermission/assignpermission
export async function assignOrgPermissions(body) {
    try {
        return await axios.post(`orguserpermission/assignpermission`, body)

    } catch (error) {
        return error;
    }
}
// API : v1/orguserpermission/updatePermission
export async function updateOrgPermissions(body) {
    try {
        return await axios.put(`orguserpermission/updatePermission`, body)

    } catch (error) {
        return error;
    }
}
// API : v1/orguserpermission/orgRolePermissionByroleId/:id
export async function getOrgPermissionsByRoleId(_, id) {
    try {
        return await axios.get(`orguserpermission/orgRolePermissionByroleId/${id}`)

    } catch (error) {
        return error;
    }
}

// API : v1/user/getroles
export async function getUserRoles() {
    try {
        return await axios.get(`user/getroles`)

    } catch (error) {
        return error;
    }
}
// ORG LEVEL USERS END

// API : v1/formfields/get
export async function getFormFields(body) {
    try {
        return await axios.post(`formfields/get`, body);

    } catch (error) {
        return error;
    }
}
// API : v1/formfields/getSections
export async function getFormSections(body) {
    try {
        return await axios.post(`formfields/getSections`, body);

    } catch (error) {
        return error;
    }
}
// API : v1/user/formfields
export async function saveFormFields(body) {
    try {
        return await axios.post(`formfields`, body);

    } catch (error) {
        return error;
    }
}

// API : v1/emailverificationtemps/getdata
export async function getPublicDataForApprovals(body) {
    try {
        return await axios.post(`/emailverificationtemps/getdata`, body);

    } catch (error) {
        return error;
    }
}

// API : v1/emailverificationtemps/approvalaction
export async function PublicApprovalAction(body) {
    try {
        return await axios.post('/emailverificationtemps/approvalaction', body);

    } catch (error) {
        return error;
    }
}
// API : v1/sendemailapprovalsetting/create
export async function CreateApprovalEmailSettings(body) {
    try {
        return await axios.post('/sendemailapprovalsetting/create', body);

    } catch (error) {
        return error;
    }
}
// API : v1/sendemailapprovalsetting/create
export async function getApprovalEmailSettingsByBranch(_, bId) {
    try {
        return await axios.get(`/sendemailapprovalsetting/getsettings/${bId}`);

    } catch (error) {
        return error;
    }
}

// Bulk Add Sections
// API : v1/academicsection/bulkCreate
export async function registerBulkSections(data) {
    try {
        return await axios.post(`/academicsection/bulkCreate`, data);

    } catch (error) {
        return error;
    }
}
// API : v1//academicsection/bulkDelete
export async function deleteBulkSections(data) {
    try {
        return await axios.put(`/academicsection/bulkDelete`,data);

    } catch (error) {
        return error;
    }
}

// API : v1/subject/getSubjects
export async function getSubjects(data) {
    try {
        return await axios.post(`subject/getSubjects`,data);

    } catch (error) {
        return error;
    }
}

// API : v1/branchsubject/getBranchSubjects
export async function getBranchSubjects(data) {
    try {
        return await axios.post(`/branchsubject/getBranchSubjects`,data);

    } catch (error) {
        return error;
    }
}

// API : v1/branchsubject/update/${id}
export async function updateBranchSubjects(data, id) {
    try {
        return await axios.put(`branchsubject/update/${id}`,data);

    } catch (error) {
        return error;
    }
}

// API : v1/branchsubject/create
export async function registerBranchSubjects(data) {
    try {
        return await axios.post(`branchsubject/create`,data);

    } catch (error) {
        return error;
    }
}

// API : v1/branchsubject/getSubjects
export async function getBranchSubjectsByUserId(data) {
    try {
        return await axios.post(`/branchsubject/getSubjects`,data);

    } catch (error) {
        return error;
    }
}

// API : v1/user/getusers
export async function getUsers(data) {
    try {
        return await axios.post(`user/getusers`,data);

    } catch (error) {
        return error;
    }
}

// Sort settings Api's Start here

// API : v1/sortsettings
export async function createSortSettings(data) {
    try {
        return await axios.post(`sortsettings`,data);

    } catch (error) {
        return error;
    }
}

// API : v1/sortsettings/student
export async function getSortSettings(id, type) {
    try {
        return await axios.get(`sortsettings/${type}/${id}`);

    } catch (error) {
        return error;
    }
}


// Sort settings Api's Ends here
