import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface Props {
    info: string;
    name: string;
}

const ExtendTooltip: React.FC<Props> = ({ info, name }) => {
    return (
        <OverlayTrigger 
            placement="bottom-start"
            overlay={<Tooltip id="tooltip" className="theme-tooltip" >{info}</Tooltip>}
        >
            <span>{name}</span>
            {/* <span>...</span> */}
        </OverlayTrigger>
    );
};

export default ExtendTooltip;
