import React from "react";

type IconButtonProps = {
  action: "edit" | "delete" | "add-regular";
  onClickHandler: (e?: any) => any;
  classes?: string;
  styles?: Object;
  formId?: string;
  disabled?: boolean;
};

const IconButton: React.FC<IconButtonProps> = ({
  action,
  onClickHandler,
  classes,
  styles,
  formId,
  disabled,
  children,
}) => {
  return (
    <button
      form={formId ? formId : ""}
      disabled={disabled ? disabled : false}
      onClick={onClickHandler}
      className={`${
        action === "edit"
          ? "theme-tabular-edit-btn"
          : action === "delete"
          ? "theme-tabular-delete-btn"
          : action === "add-regular"
          ? "theme-btn theme-action-btn d-flex align-items-center gap-2"
          : "theme-tabular-action-btn"
      } ${classes ? classes : ""}`}
      style={styles ? styles : {}}
    >
      {action === "edit" ? (
        <img
          src="/images/table-edit-btn.svg"
          style={{ marginBottom: "5px" }}
          alt="edit"
        />
      ) : action === "delete" ? (
        <img
          src="/images/delete-icon.svg"
          style={{ marginBottom: "5px" }}
          alt="delete"
        />
      ) : action === "add-regular" ? (
        <>
          <img src="/images/add-btn.svg" alt="add" /> {children}
        </>
      ) : (
        action
      )}
    </button>
  );
};

export default IconButton;
